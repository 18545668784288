<template>
  <div class="Profile">
    <div class="van-contact-edit">
      <van-image
        round
        width="5rem"
        height="5rem"
        fit="cover"
        :src=userInfo.headimgurl
      />
      <van-form @submit="onSubmit">

        <van-field
          readonly
          v-model="userInfo.nickname"
          label="昵称"
        />
          <van-field
          v-model="userInfo.name"
          label="姓名"
        />
        <van-field
          readonly
          clickable
          :value="userInfo.role"
          label="身份"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="roles"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          :value="userInfo.institution"
          label="所属机构"
          @click="showPicker2 = true"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-picker
            show-toolbar
            :columns="institution"
            @confirm="onConfirm2"
            @cancel="showPicker2 = false"
          />
        </van-popup>
        <van-field
          required
          v-model="userInfo.mobile"
          type="phone"
          label="手机号"
          :rules="[{ validator, message: '请输入格式正确的手机号' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>

<script>

import {updateUserApi} from '@/api/user'
import { Toast } from 'vant'
import { getAuth } from '@/wx'

export default {
  metaInfo: {
    title: '个人信息',
  },
  name: 'Profile',
  data() {
    return {
      userInfo: {},
      roles: ['销售', '医生', '患者'],
      institution: ['广州云量', '洛兮'],
      showPicker: false,
      showPicker2: false,
    }
  },
  created() {
    this.initUser()

  },
  methods: {
    onConfirm(value) {
      this.userInfo.role = value
      this.showPicker = false
    },
    onConfirm2(value) {
      this.userInfo.institution = value
      this.showPicker2 = false
    },
    onSubmit() {
      updateUserApi(this.userInfo.id, this.userInfo).then(res=>{
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        Toast.success('修改成功')

      })
    },
    validator(val) {
      return /1\d{10}/.test(val)
    },
  }
}
</script>

<style scoped>
.van-contact-edit {
  text-align: center;
}
.van-image {
  margin-bottom: 15px;
}
</style>
